import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// @ts-ignore
import logo from '../images/logo@2x.png'
// @ts-ignore
import uzLogo from '../images/uzupioRespublika.svg'
// @ts-ignore
import vilniusLogo from '../images/vilnius.svg'

const IndexPage = ({data}) => {
  return (
    <main>
      <h3 className="p-4 md:p-12 mx-auto max-w-xl">
        <Link to="/" 
          className={`block h-12 overflow-hidden -indent-[1000px] bg-contain bg-no-repeat bg-center`}
          style={{ backgroundImage: `url(${logo})` }}
        >Gyvos Sienos</Link>
      </h3>
      <ul className="grid mt-4 mb-12 gap-4 md:gap-8 grid-cols-2 md:grid-cols-3 mx-auto max-w-[960px] px-4 md:px-12">
        {
          // columns-2
          data.allContentfulPhoto.edges.map(({ node }) => {
            const image = getImage(node.photo?.localFile.childImageSharp)
          return <li className="break-inside-avoid" key={node.contentfulid}>
            <Link to={`/${node.contentfulid}`}>
            { image ? <GatsbyImage className="rounded-lg shadow-lg" alt={node.title} image={image} /> : <></> }
            <h2 className="mt-2 mb-4 text-center">{node.title}</h2>
            </Link>
          </li>})
        }
      </ul>
      <section className="mx-auto max-w-lg mb-32 leading-7 px-8">
        <p className="mb-4"><strong>Gyvos sienos</strong> tai Užupio Respublikos, o ir Vilniaus, istorija, vizija, nuotaika ir idėjų įvairovė pasakojama vaizdais, tai langeliai, vartai į kitą dimensiją, į kitą laiką. Šiuo atveju inkliuzas tai ne svetimkūnis skaidriame minerale, tai tikras, miniatiūrinis realybės audinys dabartiniame laike. Tai ne tik senus laikus atspindinčios fotografijos, bet ir vaizdai parodantys tos vietos grožį, subtilumą ir žavesį.</p>
        <p className="mb-4">Paskutinį dešimtmetį ir Vilnius ir Užupis ėmė stipriai keistis bei pateko į didelių permainų erdvę. Anksčiau buvęs tolimas žodis — gentrifikacija — Užupio erdvėje tapo akivaizdžiu ir aktualiu. Akivaizdu ir tai, kad prasidėjo naujas etapas, kurio metu jau pasakojamos legendos, apie tai kas buvo kadaise. Užupio Respublika tampa unikaliu muziejumi su gidais, kurie eina ir pasakoja istorijas apie kadaise čia klestėjusius menininkus ir vykusius renginius, apie iniciatyvas ir proveržius, kurie dabar kartais atrodo visai neįmanomi.</p>
        <p className="mb-4">Šiame projekte susijungia seni ir nauji laikai, susijungia per vaizdus, įspūdžius. Taip Vilnius įgauna dvi laiko dimensijas, dabartinę ir praeities viziją, istorijos langą į kurį gali pažiūrėti. Tai vieta kur gali sustoti pats ir sustabdyti laiką, gali nusifotografuoti, o gali tiesiog prisiminti.</p>
        <p className="mb-4">Taip vietos tampa gyvais atradimų šaltiniais, pilnais paprastų stebuklų, kuriuos tereikia mokėt pastebėti. Spalva, detalė, netikėtas vaizdo rakursas, daiktas, atspindys, tai kažkas tokio, kas išmuša iš rutinos ir primena apie didelę, mus supančią realybę.</p>
        <p className="mb-4">— Marius Abramavičius Neboisia</p>
        <p className="mb-4">Projektą koordinuoja ir realizuoja Vš.Į. „Užantis“.</p>
        <p className="mb-4">Projekto puslapio autorius: <a className="underline decoration-stone-400 underline-offset-2" href="https://lekevicius.com">Jonas Lekevičius</a>.</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8">
          <div className="text-center">
            <img alt="Užupio Respublikos logotipas" src={uzLogo} className="max-w-[120px] mx-auto opacity-80 mix-blend-luminosity" />
            <h5 className="mt-2">Užupio Respublika</h5>
          </div>
          <div className="text-center">
            <img alt="Vilniaus miesto savivaldybės logotipas" src={vilniusLogo} className="max-w-[120px] mx-auto opacity-80 mix-blend-luminosity" />
            <h5 className="mt-2">Projekto rėmėjas: <br/>Vilniaus miesto savivaldybė</h5>
          </div>
        </div>
      </section>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Gyvos Sienos</title>

export const query = graphql`
  query IndexQuery {
    allContentfulPhoto(sort: {fields: contentfulid}) {
      edges {
        node {
          author
          contentfulid
          title
          year
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
